<template>
    <psi-dialog title="Edit Rule" @close="$emit('close')">
        <h4 class="mb-1">
            {{ rule.Setting }} Value<v-icon class="mt-n1" color="error"
                >mdi-circle-medium</v-icon
            >
        </h4>
        <v-text-field
            label="Rule Value"
            v-model="rule.Value"
            solo
            prepend-inner-icon="mdi-text-box-check"
            persistent-hint
            :hint="`Override the value for ${rule.Setting} rule`"
            :suffix="rule.Unit"
        >
        </v-text-field>
        <h4 class="mb-1">
            Comment<v-icon class="mt-n1" color="error"
                >mdi-circle-medium</v-icon
            >
        </h4>
        <psi-form-trix-field
            v-model="comment"
            placeholder="Enter Comment"
        ></psi-form-trix-field>
    </psi-dialog>
</template>
<script>
export default {
    name: "screening-process-edit-rule",
    components: {},
    props: {
        rule: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            comment: null,
        };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {},
};
</script>

<style scoped>
</style>