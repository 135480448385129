var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "psi-dialog",
    {
      attrs: { title: "Edit Rule" },
      on: {
        close: function($event) {
          return _vm.$emit("close")
        }
      }
    },
    [
      _c(
        "h4",
        { staticClass: "mb-1" },
        [
          _vm._v(" " + _vm._s(_vm.rule.Setting) + " Value"),
          _c("v-icon", { staticClass: "mt-n1", attrs: { color: "error" } }, [
            _vm._v("mdi-circle-medium")
          ])
        ],
        1
      ),
      _c("v-text-field", {
        attrs: {
          label: "Rule Value",
          solo: "",
          "prepend-inner-icon": "mdi-text-box-check",
          "persistent-hint": "",
          hint: "Override the value for " + _vm.rule.Setting + " rule",
          suffix: _vm.rule.Unit
        },
        model: {
          value: _vm.rule.Value,
          callback: function($$v) {
            _vm.$set(_vm.rule, "Value", $$v)
          },
          expression: "rule.Value"
        }
      }),
      _c(
        "h4",
        { staticClass: "mb-1" },
        [
          _vm._v(" Comment"),
          _c("v-icon", { staticClass: "mt-n1", attrs: { color: "error" } }, [
            _vm._v("mdi-circle-medium")
          ])
        ],
        1
      ),
      _c("psi-form-trix-field", {
        attrs: { placeholder: "Enter Comment" },
        model: {
          value: _vm.comment,
          callback: function($$v) {
            _vm.comment = $$v
          },
          expression: "comment"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }